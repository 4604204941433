
import { Component, Mixins, } from 'vue-property-decorator';
import SingleImageUpload from '@/component/single-image-upload.vue';
import { request as axios } from '@/util/request';
import { getValidatorMap, Validate, Validator } from '@/mixin/validator';
import NEditor from '@/component/NForm/NEditor/NEditor.vue';

@Component({
  name:'Setting',
  components: {
    SingleImageUpload,
    NEditor
  },
})
export default class Setting extends Mixins(Validate) {
  form_item_layout: any = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
  };
  // 页面加载
  is_loading: boolean = false
  // 表单
  form: any = {
    rule: '<p>hello</p>',
    name:""
  }
  dis: boolean = true

  async save() {
    if (!this.validateCommit()) {
      this.$message.error("必填项未填写")
      return;
    }
    const data = {
      ...this.form,
    }
    const res = await axios.put("/boss/vip/config/0",data)
    if (res.status !== 200) {
      this.$message.error((res as any).message)
    } else {
      this.$message.success("保存成功")
      this.dis = true

    }
  }
  compile() {
    this.dis = false
  }
  async created() {
    const res = await axios.get("/boss/vip/config")
    if (res.status !== 200) {
      this.$message.error((res as any).message)
      return
    }
    if (res.data && res.data !== null) {
      if (Array.isArray(res.data)) {
        return
      }
      this.form.rule = res.data.rule
      this.form.name = res.data.name ? res.data.name : "纽澜地超级会员"
    }
  }

}
